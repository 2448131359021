<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="80" style="padding: 2rem">
        <el-col :span="12">
          <el-form >
            <el-form-item label="帮扶金比例">
              <el-input-number v-model="form.help_fee_scale"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="帮扶金解冻规则[多选]"><br>
              <el-checkbox-group v-model="helpFunc">
                <div>
                  <el-checkbox label="link-agent">
                    自身邀请关系链下要有至少<el-input-number v-model="form.help_fee_change_limit_total_invite_agent"></el-input-number>个代理
                  </el-checkbox>
                </div>
                <div>
                  <el-checkbox label="help-user-boss">两个帮扶用户须升级为老板</el-checkbox>
                </div>
                <div>
                  <el-checkbox label="help-user-link-agent">
                    两个帮扶用户邀请关系链合计至少有<el-input-number v-model="form.help_fee_change_limit_total_help_user_link_agent"></el-input-number>个代理
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="帮扶金解冻后">金额
              <el-input-number v-model="form.help_fee_change_to_cost_scale"></el-input-number>%划入消费账户
            </el-form-item>
            <el-form-item label="微信提现">
              <el-switch v-model="form.withdrawal_wechat"></el-switch>
            </el-form-item>
            <el-form-item label="提现手续费">
              <el-input-number v-model="form.withdrawal_fee"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="升级代理">
              加盟专区消费金额满
              <el-input-number v-model="form.up_agent_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="升级老板">
              直推
              <el-input-number v-model="form.up_boos"></el-input-number>
              个代理
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="代理直推奖励">
              <el-input-number v-model="form.agent_invite_award_scale"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="老板直推奖励">
              <el-input-number v-model="form.boos_invite_award_scale"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="老板见单奖励">
              <el-input-number v-model="form.jian_dan_award_scale"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="老板平级奖励">
              <el-input-number v-model="form.ping_ji_award_scale"></el-input-number>
              %
            </el-form-item>
            <el-form-item label="奖励发放限制">
              <div class="flex-def flex-cCenter">
                <el-switch v-model="form.award_intercept"></el-switch>
                <div class="y-desc">开启后以上四个奖励类型实际发放金额最高不超过 自购加盟区商品金额 X 所得奖励比例</div>
              </div>
            </el-form-item>
            <el-form-item label="老板、代理每月免费领">
              <el-input-number v-model="form.free_num"></el-input-number>
              个福利专区产品
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="分佣金额显示">
              <el-switch v-model="form.goods_award_base_fee"></el-switch>
              分佣金额单位
              <el-input v-model="form.goods_award_base_fee_unit" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="复购限制">
              <el-switch v-model="form.repurchase_power"></el-switch>
              复购触发金额
              <el-input-number v-model="form.repurchase_limit_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="余额转账手续费">
              <el-input-number v-model="form.balance_transfer_rate"></el-input-number>%
            </el-form-item>
            <el-form-item label="平级奖励发放层级">
              <el-input-number v-model="form.ping_ji_layer"></el-input-number>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="升级老板留人策略">
              <el-radio-group v-model="form.up_boos_retain_person">
                <el-radio :label="0">只留帮扶用户</el-radio>
                <el-radio :label="1">留帮扶用户及帮扶用户邀请关系链</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="会员别名">
              <el-input v-model="form.level_normal_alias" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="代理别名">
              <el-input v-model="form.level_agent_alias" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="老板别名">
              <el-input v-model="form.level_boos_alias" style="width: 10rem"></el-input>
            </el-form-item>
            <el-form-item label="关闭奖励">
              <el-switch v-model="form.award_close"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form label-width="8rem">
            <el-form-item label="福利区分享标题">
              <el-input v-model="form.free_share_title"></el-input>
            </el-form-item>
            <el-form-item label="福利区分享描述">
              <el-input v-model="form.free_share_desc"></el-input>
            </el-form-item>
            <el-form-item label="福利区分享图标">
              <y_upload_img v-model="form.free_share_pic"></y_upload_img>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-width="8rem">
            <el-form-item label="加盟区分享标题">
              <el-input v-model="form.upgrade_share_title"></el-input>
            </el-form-item>
            <el-form-item label="加盟区分享描述">
              <el-input v-model="form.upgrade_share_desc"></el-input>
            </el-form-item>
            <el-form-item label="加盟区分享图标">
              <y_upload_img v-model="form.upgrade_share_pic"></y_upload_img>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form label-width="8rem">
            <el-form-item label="福利区首页列表背景">
              <y_upload_img v-model="form.free_home_bg"></y_upload_img>
            </el-form-item>
            <el-form-item label="福利区首页列表头图">
              <y_upload_img v-model="form.free_home_banner"></y_upload_img>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-width="8rem">
            <el-form-item label="加盟区首页列表背景">
              <y_upload_img v-model="form.upgrade_home_bg"></y_upload_img>
            </el-form-item>
            <el-form-item label="加盟区首页列表头图">
              <y_upload_img v-model="form.upgrade_home_banner"></y_upload_img>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "set",
  components: {y_upload_img},
  data() {
    return {
      list:[],
      helpFunc:[],
      form:{
        help_fee_change_func:"",
        help_fee_change_limit_total_help_user_link_agent:0,
        help_fee_scale:0,
        help_fee_change_limit_total_invite_agent:0,
        help_fee_change_to_cost_scale:0,

        up_agent_fee:0,
        up_boos:0,
        agent_invite_award_scale:0,
        boos_invite_award_scale:0,
        jian_dan_award_fee:0,
        ping_ji_award_scale:0,
        free_num:0,

        withdrawal_wechat:false,
        withdrawal_fee:0,

        level_normal_alias:"",
        level_agent_alias:"",
        level_boos_alias:"",

        free_share_title:"",
        free_share_desc:"",
        free_share_pic:"",
        upgrade_share_title:"",
        upgrade_share_desc:"",
        upgrade_share_pic:"",

        goods_award_base_fee:false,
        goods_award_base_fee_unit:"",

        repurchase_power:false,
        repurchase_limit_fee:0,

        free_home_bg:"",
        free_home_banner:"",

        upgrade_home_bg:"",
        upgrade_home_banner:"",

        balance_transfer_rate:0,

        ping_ji_layer:0,

        jian_dan_award_scale:0,
        award_intercept:false,

        up_boos_retain_person:0,

        award_close:false,
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.sys.rule().then(res=>{
        if (res.help_fee_change_func !== ""){
          this.helpFunc = res.help_fee_change_func.split(",");
        }
        this.form = res;
      })
    },
    edit(){
      if(this.helpFunc.length > 0)this.form.help_fee_change_func = this.helpFunc.join(",");
      this.$u.api.shop.sys.ruleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>